import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from './ui/input';
import Checkbox from './ui/checkbox';
import { checkboxes, inputs } from '../const/staticVariables';

export default function Form() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState<{
    form: Record<string, string | boolean>;
  }>({
    form: {},
  });
  const captchaRef = useRef<ReCAPTCHA>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      form: {
        ...prevData.form,
        [name]: value,
      },
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      form: {
        ...prevData.form,
        [name]: checked,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (formData.form['description']) {
      setTimeout(() => {
        setIsFormSubmitted(true);
        alert('Form successfully submitted!');
      }, 1000);

      return;
    }
    const tokenRes = await captchaRef.current?.executeAsync();
    if (!tokenRes) {
      return;
    }

    const data = { ...formData.form, recaptcha: tokenRes };

    try {
      await fetch(process.env.REACT_APP_BASE_URL + 'api/form/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      setIsFormSubmitted(true);
    } catch (error: any) {
      setIsSubmitting(false);
      setFormError(error.toString());
    }
  };

  return (
    <div className="bg-white bg-opacity-55 rounded-xl h-full w-full p-8">
      <img src="icons/earth-search.svg" alt="earth" className="w-10 h-10" />
      <div className="bebas text-3xl text-text-primary mt-8">
        Discover Our Exclusive projects
      </div>
      {isFormSubmitted ? (
        <div className="w-100 text-center m-4">
          Form submitted successfully.
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          {inputs.map((input) => (
            <Input
              required={input.required}
              key={input.name}
              name={input.name}
              type={input.type}
              placeholder={input.placeholder}
              // maxLength=input.max
              value={formData.form[input.name]?.toString() || ''}
              onChange={handleInputChange}
              className={input.name === 'description' ? 'hidden' : ''}
                {...(input.maxLength && { maxLength: input.maxLength })}
            />
          ))}

          {checkboxes.map((checkbox) => (
            <Checkbox
              key={checkbox.name}
              name={checkbox.name}
              label={checkbox.label}
              checked={!!formData.form[checkbox.name] || false}
              onChange={handleCheckboxChange}
            />
          ))}

          <ReCAPTCHA
            ref={captchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_CAPTCHA || ''}
          />

          <button
            type="submit"
            className="bg-background-blue px-6 py-2 flex mt-4 rounded-lg border border-white"
            disabled={isSubmitting}
          >
            <img
              src="icons/mail-icon.svg"
              alt="mail-icon"
              className="w-6 h-6"
            />
            <span className="poppins text-white pl-2 font-normal text-lg">
              {isSubmitting ? "Submitting..." : "Submit Your Request"}
            </span>
          </button>
          {formError ? <div>{formError}</div> : ""}
        </form>
      )}
    </div>
  );
}
