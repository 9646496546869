type InputProps = {
  type: string;
  name: string;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
};

export default function Input(props: InputProps) {
  const { type, placeholder, value, onChange, maxLength, className, name, required } =
    props;
  return (
    <input
      type={type}
      name={name}
      value={value}
      required={required}
      onChange={onChange}
      placeholder={placeholder}
      className={`w-full mt-4 h-12 poppins text-text-placeholder font-light rounded-lg p-4 ${className}`}
      {...(maxLength && { maxLength })}
    />
  );
}
