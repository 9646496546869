import React from "react";
import {Helmet} from "react-helmet";

interface TagProps {
    title: string,
    description: string,
    image_url: string,
    keywords?: string
}

const Tags: React.FC<TagProps> = (props) => {
    const {
        title,
        description,
        image_url,
        keywords = "website, social media, seo, content, peripol, works, showreel",
    } = props

    const currentUrl = `https://peripol.com`;

    return (
        <Helmet>
            <meta charSet="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
            <meta name="author" content="Ozware LTD."/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:url" content={currentUrl}/>
            <meta property="og:image" content={image_url}/>
            <meta property="og:image:width" content="720"/>
            <meta property="og:image:height" content="374"/>
            <meta property="og:site_name" content={title}/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:updated_time" content="2024-11-25T09:43:53.250Z"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={image_url}/>
            <meta name="twitter:image:alt" content={description}/>
            {/*<meta name="twitter:creator" content="@yourtwitterhandle"/>*/}
            {/*<meta name="twitter:site" content="@yourtwitterhandle"/>*/}
            <meta name="linkedin:card" content="summary_large_image"/>
            <meta name="twitter:url" content={currentUrl}/>
            <meta name="linkedin:title" content={title}/>
            <meta name="linkedin:description" content={description}/>
            <meta name="linkedin:image" itemProp="image" content={image_url}/>
            <title>{title}</title>
            <link rel="icon" href="https://peripol.com/favicon.ico" type="image/x-icon"/>
            <link itemProp="thumbnailUrl" href={image_url} />
            <link rel="canonical" href={currentUrl}/>
        </Helmet>
    );
}

export default Tags